.dropdown-select {
  position: relative;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .dropdown-select .dropdown-select__container .dropdown-select__input,
  .dropdown-select .dropdown-select__container .dropdown-select__input:focus {
    border: none;
    outline: none; }
  .dropdown-select .dropdown-select__container .dropdown-select__input {
    box-sizing: border-box;
    height: 36px;
    width: 100%;
    font-size: 1rem;
    padding: 6px 37px 6px 12px;
    border-radius: 0.25rem; }
  .dropdown-select .dropdown-select__container .dropdown-select__arrow {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,eeeeee+100 */
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0 );
    /* IE6-9 */
    display: inline-block;
    width: 25px;
    height: 36px;
    cursor: pointer;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
    position: absolute;
    right: 0px;
    top: 0px; }
    .dropdown-select .dropdown-select__container .dropdown-select__arrow .dropdown-select__arrow-icon {
      position: absolute;
      top: 45%;
      right: 7px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent; }
    .dropdown-select .dropdown-select__container .dropdown-select__arrow .arrow-down {
      border-top: 5px solid gray; }
    .dropdown-select .dropdown-select__container .dropdown-select__arrow .arrow-up {
      border-bottom: 5px solid gray; }
  .dropdown-select .dropdown-select__container .dropdown-select__select-deselect-all {
    position: absolute;
    top: 23%;
    right: 35px; }
    .dropdown-select .dropdown-select__container .dropdown-select__select-deselect-all input {
      width: 20px;
      height: 20px; }
  .dropdown-select .dropdown-select__container .dropdown-select__spinner {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 8px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: dropdown-select__spinner 0.6s linear infinite; }

@keyframes dropdown-select__spinner {
  to {
    transform: rotate(360deg); } }
  .dropdown-select .dropdown-select__options {
    z-index: 9999;
    position: absolute;
    background-color: white;
    max-height: 180px;
    overflow-x: hidden;
    overflow-y: auto;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 100%; }
    .dropdown-select .dropdown-select__options .dropdown-select__options__option {
      padding: 6px 12px;
      cursor: pointer; }
    .dropdown-select .dropdown-select__options .dropdown-select__options__option:hover,
    .dropdown-select .dropdown-select__options .dropdown-select__options__option--focused {
      background-color: #0069d9;
      color: white; }
    .dropdown-select .dropdown-select__options .dropdown-select__options__checkbox-option input {
      margin-right: 10px; }
  .dropdown-select .dropdown-select__options-open {
    display: block; }
  .dropdown-select .dropdown-select__options-close {
    display: none; }

.dropdown-select--focused {
  border-color: #80bdff; }
